.img {
  margin: 10px;
  height: 70px;
  padding: 5px;
  border: 1px solid black;
}

.selectedimg {
  margin: 10px;
  height: 65px;
  padding: 5px;
  border: 2px solid black;
}

.custom-style {
  width: 100%;
  height: 40px;
  margin-left: 5px;
  padding-left: 5px;
  border-radius: 5px;
  border: 1px solid silver;
}